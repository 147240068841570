import React from 'react'
import {graphql, Link} from 'gatsby'
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {Card, Col, Container, Row} from "react-bootstrap";
import {IoArrowForward} from "react-icons/io5";
import Img from "gatsby-image";


const Blog = ({ data }) => {
	return (
		<Layout>
			<Seo canonical="/blog/" title="Sweepstakes Marketing Strategy, Tips and Tricks" description="Guides, ideas, and inspiration from Arbitery to help brands, content creators and influencers to grow their audience." />
			<Container className="pb-5 pt-3">
				<h1 className="mb-3 h2">Tips to Grow Your Business with Sweepstakes and Giveaways</h1>
				<p className="text-muted">
					Guides, ideas, and inspiration from Arbitery to help brands, content creators and influencers to grow their audience.
				</p>
				<Row>
					{data.allPrismicPost.nodes.map((node) => {
						const {
							url,
							data: { title: { text: title }, description, preview_image: { thumbnails: { small: { url: image } } } }
						} = node;
						return (
							<Col xs="12" md="4">
								<Card className="shadow-sm mb-3">
									<Link to={url} title={title}>
										<Card.Img as={Img} variant="top" fluid={image} width="350" height="150" alt={title} />
									</Link>
									<Card.Body>
										<Card.Title>
											{title}
										</Card.Title>
										<Card.Text>
											{description.length > 150 ? `${description.slice(0, 150)}...` : description}
										</Card.Text>
										<Card.Link as={Link} to={url} title={title}>Read more&nbsp;<IoArrowForward /></Card.Link>
									</Card.Body>
								</Card>
							</Col>
						);
					})}
				</Row>
			</Container>
		</Layout>
	)
}

export const query = graphql`
    query BlogQuery {
        allPrismicPost {
            nodes {
                url
                data {
                    title { text }
                    description
                    preview_image {
		                    thumbnails {
				                    small {
						                    url:fluid(maxWidth: 350) {
                                    ...GatsbyPrismicImageFluid
                                }
				                    }
		                    }
                    }
                }
            }
        }
    }
`

export default Blog

